import Header from 'components/Headers/Header';
import { TableBody } from 'components/Table';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableData } from 'components/Table';
import { TableHeader } from 'components/Table';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';
import { showDashboardResume } from 'services/Report/Dashboard';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import { CaretRight, ContentWrapper, MobileTitle } from '../styles';
import '../style/resume.css';
import InputStoreComponent from 'components/Form/Input/InputStoreComponent';

const DashboardResume = () => {
  const history = useHistory();

  const urlPage = {
    2: '/admin/fgts/proposal',
    4: '/admin/auxilio-brasil/simulation',
    5: '/admin/card-benefit/simulation',
    1: '/admin/inss',
  };
  const [tableDetails, setTableDetails] = useState(null);
  const [monthSelected, setMonthSelected] = useState(0);
  const [productSelected, setProductSelected] = useState(2);
  const [resumeStatusPercent, setResumeStatusPercent] = useState({
    PERCENT_PAID: 0,
    PERCENT_CANCEL: 0,
    PERCENT_FAILED: 0,
    PERCENT_PENDING: 0,
    PERCENT_WAIT: 0,
    PERCENT_ANALYSE: 0,
  });
  const [resumeStatusFGTS, setResumeStatusFGTS] = useState({
    AGUARDANDO: { value: 0, qtd: 0 },
    PAGO: { value: 0, qtd: 0 },
    ANALISE: { value: 0, qtd: 0 },
    PENDENCIA: { value: 0, qtd: 0 },
    REPROVADO: { value: 0, qtd: 0 },
    CANCELADO: { value: 0, qtd: 0 },
  });
  const [rankingIndication, setRankingIndication] = useState([]);
  const [resumeStatusBANK, setResumeStatusBANK] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    storeId: null,
  });
  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleShowDashboardResume = (search = { month: 0, service: 2 }) => {
    Swal.fire({
      title: 'Carregando...',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    showDashboardResume({
      month: search.month,
      service: search.service,
      filter: filter,
    })
      .then(({ data }) => {
        const resultSet = data.resultSet;
        setResumeStatusFGTS(resultSet.resumeStatusFGTS);
        setRankingIndication(resultSet.rankingIndication);
        setResumeStatusBANK(resultSet.resumeStatusBANK);
        const result = {
          ...resultSet.resumeStatusPercent,
          PERCENT_PAID: Number(
            (resultSet.resumeStatusPercent.paid * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
          PERCENT_CANCEL: Number(
            (resultSet.resumeStatusPercent.cancel * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
          PERCENT_FAILED: Number(
            (resultSet.resumeStatusPercent.failed * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
          PERCENT_PENDING: Number(
            (resultSet.resumeStatusPercent.pending * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
          PERCENT_WAIT: Number(
            (resultSet.resumeStatusPercent.wait * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
          PERCENT_ANALYSE: Number(
            (resultSet.resumeStatusPercent.analyse * 100) /
              resultSet.resumeStatusPercent.total
          ).toFixed(2),
        };
        setResumeStatusPercent(result);
        Swal.close();
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleRedirect = (
    filter = { dateStart: null, dateEnd: null, statusId: null }
  ) => {
    if (!filter.dateStart || !filter.dateEnd) {
      Swal.fire({
        icon: 'warning',
        title: 'Ops!',
        text: 'Não possuem dados para consulta',
      });
      return;
    }

    history.push(
      `${urlPage[productSelected]}?dateStart=${filter.dateStart}&dateEnd=${filter.dateEnd}&statusId=${filter.statusId}`
    );
  };

  useEffect(() => {
    handleShowDashboardResume();
  }, []);

  return (
    <>
      <Header titlePage="Resumo de vendas" />
      <Container className="mt--9" fluid>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2 className="m-0">Principais produtos</h2>
          </CardHeader>
          <CardBody className="bg-secondary">
            <Row>
              <Col md="4">
                <Button
                  color="white"
                  size="lg"
                  className="w-100 font-18"
                  onClick={() => {
                    history.push('/admin/index');
                  }}
                >
                  <i className="fas fa-link" /> FGTS
                </Button>
              </Col>
              <Col md="4">
                <Button
                  color="white"
                  size="lg"
                  className="w-100 font-18"
                  onClick={() => {
                    history.push('/admin/inss/proposal/report');
                  }}
                >
                  <i className="fas fa-link" /> CONSIGNADO
                </Button>
              </Col>
              <Col md="4">
                <Button
                  color="white"
                  size="lg"
                  className="w-100 font-18"
                  onClick={() => {
                    history.push('/admin/personal-credit/simulation');
                  }}
                >
                  <i className="fas fa-link" /> CREDITO PESSOAL
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className="shadow mb-4">
          <CardHeader>
            <h2 className="m-0">
              <i className="fas fa-tachometer-alt"></i> Resumo de Vendas
            </h2>
          </CardHeader>
          <CardBody>
            <fieldset className="bg-secondary border p-4 mb-3">
              <legend className="text-left m-0">Filtro</legend>
              <div>
                <Button
                  color={showFilter ? 'info' : 'secondary'}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <i className="fas fa-filter"></i> Personalizado
                </Button>
                <Button
                  color={monthSelected <= 0 ? 'success' : 'secondary'}
                  onClick={() => {
                    setMonthSelected(0);
                    setShowFilter(false);
                    handleShowDashboardResume({
                      month: 0,
                      service: productSelected,
                    });
                  }}
                >
                  <i className="far fa-calendar-plus" /> Mês Atual
                </Button>
                <Button
                  color={monthSelected > 0 ? 'success' : 'secondary'}
                  onClick={() => {
                    setMonthSelected(1);
                    setShowFilter(false);
                    handleShowDashboardResume({
                      month: 1,
                      service: productSelected,
                    });
                  }}
                >
                  <i className="far fa-calendar-minus" /> Mês Anterior
                </Button>
              </div>
              {showFilter && (
                <Card className="shadow mt-3">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label>Período</Label>
                          <InputGroup>
                            <Input
                              id="dateStart"
                              name="dateStart"
                              type="date"
                              className="border"
                              onChange={(ev) => {
                                setFilter({
                                  ...filter,
                                  dateStart: ev.target.value,
                                });
                              }}
                            />
                            <Input
                              id="dateEnd"
                              name="dateEnd"
                              type="date"
                              className="border pl-3"
                              onChange={(ev) => {
                                setFilter({
                                  ...filter,
                                  dateEnd: ev.target.value,
                                });
                              }}
                            />

                            <select
                              className="form-control border pl-2"
                              id="typeDate"
                              name="typeDate"
                              onChange={(ev) => {
                                setFilter({
                                  ...filter,
                                  typeDate: ev.target.value,
                                });
                              }}
                              onBlur={(ev) => {
                                setFilter({
                                  ...filter,
                                  typeDate: ev.target.value,
                                });
                              }}
                            >
                              <option value="createdAt">
                                DATA DE CADASTRO
                              </option>
                              <option value="updatedAt">
                                DATA DE ATUALIZAÇÃO
                              </option>
                            </select>
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <InputStoreComponent
                          filter={filter}
                          setFilter={(e) => {
                            setFilter({
                              ...filter,
                              storeId: e.storeId,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      color="success"
                      onClick={() => {
                        handleShowDashboardResume({
                          month: -1,
                          service: productSelected,
                        });
                      }}
                    >
                      Buscar
                    </Button>
                  </CardFooter>
                </Card>
              )}
              <div className="mt-3">
                <p className="m-0">Produto</p>
                <Button
                  color={productSelected === 2 ? 'success' : 'secondary'}
                  onClick={() => {
                    setProductSelected(2);
                    handleShowDashboardResume({
                      month: monthSelected,
                      service: 2,
                    });
                  }}
                >
                  FGTS
                </Button>
                <Button
                  color={productSelected === 6 ? 'success' : 'secondary'}
                  onClick={() => {
                    setProductSelected(6);
                    handleShowDashboardResume({
                      month: monthSelected,
                      service: 6,
                    });
                  }}
                >
                  CRÉDITO PESSOAL
                </Button>
                <Button
                  color={productSelected === 1 ? 'success' : 'secondary'}
                  onClick={() => {
                    setProductSelected(1);
                    handleShowDashboardResume({
                      month: monthSelected,
                      service: 1,
                    });
                  }}
                >
                  CONSIGNADO
                </Button>
              </div>
            </fieldset>
            <Row>
              <Col md="12">
                <Row>
                  <Col md="4">
                    <h3 className="mt-2 mb-0">
                      <i className="fas fa-thumbs-up" /> PAGO
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart: resumeStatusFGTS.PAGO.startDay ?? null,
                          dateEnd: resumeStatusFGTS.PAGO.endDay ?? null,
                          statusId: resumeStatusFGTS.PAGO.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-success">
                          R${' '}
                          {resumeStatusFGTS.PAGO
                            ? resumeStatusFGTS.PAGO.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.PAGO
                            ? resumeStatusFGTS.PAGO.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <h3 className="mt-2 mb-0">
                      <i className="fas fa-hourglass-half" /> AGUARDANDO
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart:
                            resumeStatusFGTS.AGUARDANDO.startDay ?? null,
                          dateEnd: resumeStatusFGTS.AGUARDANDO.endDay ?? null,
                          statusId:
                            resumeStatusFGTS.AGUARDANDO.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-warning">
                          R${' '}
                          {resumeStatusFGTS.AGUARDANDO
                            ? resumeStatusFGTS.AGUARDANDO.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.AGUARDANDO
                            ? resumeStatusFGTS.AGUARDANDO.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <h3 className="mt-2 mb-0">
                      <i className="fas fa-exclamation-circle" /> COM PENDÊNCIA
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart:
                            resumeStatusFGTS.PENDENCIA.startDay ?? null,
                          dateEnd: resumeStatusFGTS.PENDENCIA.endDay ?? null,
                          statusId: resumeStatusFGTS.PENDENCIA.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-primary">
                          R${' '}
                          {resumeStatusFGTS.PENDENCIA
                            ? resumeStatusFGTS.PENDENCIA.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.PENDENCIA
                            ? resumeStatusFGTS.PENDENCIA.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <h3 className="mt-4 mb-0">
                      <i className="fas fa-pray" /> EM ANALISE
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart: resumeStatusFGTS.ANALISE.startDay ?? null,
                          dateEnd: resumeStatusFGTS.ANALISE.endDay ?? null,
                          statusId: resumeStatusFGTS.ANALISE.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-info">
                          R${' '}
                          {resumeStatusFGTS.ANALISE
                            ? resumeStatusFGTS.ANALISE.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.ANALISE
                            ? resumeStatusFGTS.ANALISE.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <h3 className="mt-4 mb-0">
                      <i className="fas fa-ban" /> REPROVADO
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart:
                            resumeStatusFGTS.REPROVADO.startDay ?? null,
                          dateEnd: resumeStatusFGTS.REPROVADO.endDay ?? null,
                          statusId: resumeStatusFGTS.REPROVADO.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-dark">
                          R${' '}
                          {resumeStatusFGTS.REPROVADO
                            ? resumeStatusFGTS.REPROVADO.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.REPROVADO
                            ? resumeStatusFGTS.REPROVADO.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <h3 className="mt-4 mb-0">
                      <i className="fas fa-ban" /> CANCELADO
                    </h3>
                    <Card
                      className="mt-1 mb-1 card-hover shadow bg-secondary"
                      onClick={() => {
                        handleRedirect({
                          dateStart:
                            resumeStatusFGTS.CANCELADO.startDay ?? null,
                          dateEnd: resumeStatusFGTS.CANCELADO.endDay ?? null,
                          statusId: resumeStatusFGTS.CANCELADO.statusId ?? null,
                        });
                      }}
                    >
                      <CardBody>
                        <h1 className="m-0 text-danger">
                          R${' '}
                          {resumeStatusFGTS.CANCELADO
                            ? resumeStatusFGTS.CANCELADO.value
                            : 0}
                        </h1>
                      </CardBody>
                      <CardFooter className="pt-2 pb-2">
                        Propostas{' '}
                        <span className="badge badge-secondary">
                          {resumeStatusFGTS.CANCELADO
                            ? resumeStatusFGTS.CANCELADO.qtd
                            : 0}
                        </span>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="w-100 mt-3">
              <div
                id="LINE_PAID"
                style={{
                  width: `${resumeStatusPercent.PERCENT_PAID}%`,
                }}
                className="bg-success text-white chart_line_resume font-12"
              >
                PAGO
              </div>
              <UncontrolledTooltip delay={0} target="LINE_PAID">
                PAGO - R$ {moneyMask(resumeStatusPercent.paid)} [
                {resumeStatusPercent.PERCENT_PAID}%]
              </UncontrolledTooltip>
              <div
                id="LINE_WAIT"
                style={{
                  width: `${resumeStatusPercent.PERCENT_WAIT}%`,
                }}
                className="bg-warning chart_line_resume font-12"
              >
                AGUARDANDO
              </div>
              <UncontrolledTooltip delay={0} target="LINE_WAIT">
                AGUARDANDO - R$ {moneyMask(resumeStatusPercent.wait)} [
                {resumeStatusPercent.PERCENT_WAIT}%]
              </UncontrolledTooltip>
              <div
                id="LINE_PENDING"
                style={{
                  width: `${resumeStatusPercent.PERCENT_PENDING}%`,
                }}
                className="bg-primary chart_line_resume font-12"
              >
                PENDÊNCIA
              </div>
              <UncontrolledTooltip delay={0} target="LINE_PENDING">
                PENDÊNCIA - R$ {moneyMask(resumeStatusPercent.pending)} [
                {resumeStatusPercent.PERCENT_PENDING}%]
              </UncontrolledTooltip>
              <div
                id="LINE_ANALYSE"
                style={{
                  width: `${resumeStatusPercent.PERCENT_ANALYSE}%`,
                }}
                className="bg-info chart_line_resume font-12"
              >
                ANALISE
              </div>
              <UncontrolledTooltip delay={0} target="LINE_ANALYSE">
                ANALISE - R$ {moneyMask(resumeStatusPercent.analyse)} [
                {resumeStatusPercent.PERCENT_ANALYSE}%]
              </UncontrolledTooltip>
              <div
                id="LINE_FAILED"
                style={{
                  width: `${resumeStatusPercent.PERCENT_FAILED}%`,
                }}
                className="bg-dark text-white chart_line_resume font-12"
              >
                REPROVADO
              </div>
              <UncontrolledTooltip delay={0} target="LINE_FAILED">
                REPROVADO - R$ {moneyMask(resumeStatusPercent.failed)} [
                {resumeStatusPercent.PERCENT_FAILED}%]
              </UncontrolledTooltip>
              <div
                id="LINE_CANCEL"
                style={{
                  width: `${resumeStatusPercent.PERCENT_CANCEL}%`,
                }}
                className="bg-danger text-white chart_line_resume font-12"
              >
                CANCELADO
              </div>
              <UncontrolledTooltip delay={0} target="LINE_CANCEL">
                CANCELADO - R$ {moneyMask(resumeStatusPercent.cancel)} [
                {resumeStatusPercent.PERCENT_CANCEL}%]
              </UncontrolledTooltip>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="m-0">Contratos Pagos por BANCO</h2>
          </CardHeader>
          <div>
            <Table>
              <TableHeader>
                <TableHead>BANCO</TableHead>
                <TableHead className="d-none d-lg-table-cell text-center">
                  PROPOSTAS
                </TableHead>
                <TableHead className="text-right">VLR. TOTAL</TableHead>
                <TableHead className="d-xl-none" />
              </TableHeader>
              <TableBody>
                {resumeStatusBANK.length > 0 ? (
                  resumeStatusBANK.map((register, key) => (
                    <>
                      <TableRow key={key}>
                        <TableData>{register.bank}</TableData>
                        <TableData className="d-none d-lg-table-cell text-center">
                          {register.qtd}
                        </TableData>
                        <TableData className="text-right">
                          <h3 className="m-0">
                            R$ {moneyMask(register.valueLiberty)}
                          </h3>
                        </TableData>
                        <TableData className="d-xl-none">
                          <CaretRight
                            active={tableDetails === key}
                            onClick={() => handleTableDetails(key)}
                          />
                        </TableData>
                      </TableRow>
                      {tableDetails === key && (
                        <>
                          <TableRow className="d-xl-none">
                            <TableData colSpan={10}>
                              <ContentWrapper className="d-lg-none">
                                <MobileTitle>Quantidade Propostas</MobileTitle>
                                {register.qtd}
                              </ContentWrapper>
                            </TableData>
                          </TableRow>
                        </>
                      )}
                    </>
                  ))
                ) : (
                  <>
                    <TableRow>
                      <TableData colSpan={3}>
                        <h3>Não foram identificados registros</h3>
                      </TableData>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </Card>
        {rankingIndication.length > 0 ? (
          <>
            <Card className="mt-4">
              <CardHeader>
                <h2 className="m-0">TOP 10 Vendedor / Indicação</h2>
              </CardHeader>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>RANK</TableHead>
                    <TableHead>VENDEDOR/INDICAÇÃO</TableHead>
                    <TableHead className="d-none d-lg-table-cell text-center">
                      QTD. VENDAS
                    </TableHead>
                    <TableHead className="d-none d-xl-table-cell text-right">
                      VLR. TOTAL VENDAS
                    </TableHead>
                    <TableHead className="d-xl-none" />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {rankingIndication.map((data, index) => (
                    <>
                      <TableRow key={index}>
                        <TableData>{index + 1}</TableData>
                        <TableData>{data.name}</TableData>
                        <TableData className="d-none d-lg-table-cell text-center">
                          {data.qtd}
                        </TableData>
                        <TableData className="d-none d-lg-table-cell text-right">
                          <h3 className="m-0">R$ {moneyMask(data.value)}</h3>
                        </TableData>
                        <TableData className="d-xl-none">
                          <CaretRight
                            active={tableDetails === index}
                            onClick={() => handleTableDetails(index)}
                          />
                        </TableData>
                      </TableRow>
                      {tableDetails === index && (
                        <>
                          <TableRow className="d-xl-none">
                            <TableData colSpan={10}>
                              <ContentWrapper className="d-lg-none">
                                <MobileTitle>Quantidade Vendas</MobileTitle>
                                {data.qtd}
                              </ContentWrapper>
                              <ContentWrapper className="d-lg-none">
                                <MobileTitle>Valor Total Vendas</MobileTitle>
                                <h3 className="m-0">
                                  R$ {moneyMask(data.value)}
                                </h3>
                              </ContentWrapper>
                            </TableData>
                          </TableRow>
                        </>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Card>
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default DashboardResume;
